



























































import { Component } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import ManagerDiscountModule from '@/store/modules/manager/discounts'
// types
import {
  CourseType,
  NameValueResource,
  SalePackageUnitResource,
  SaleResource, SaleResourcePaginator,
  SaleStatus, SaleUnitType,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import { formatDateRange, tableOptionsToParams } from '@/utils/functions'
import { GET_DEFAULT_PAGINATOR } from '@/store'
import DictionaryModule from '@/store/modules/dictionary'
// utils
import { getMonthsRange } from '@/utils/functions'

@Component({
  components: {
    DateInput,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class SubjectDiscountsTable extends NotifyMixin {
  /*private filter: ITableOptions = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }*/

  /*private filter: any = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }*/

  private get filter() {
    return ManagerDiscountModule.discountsFilter
  }

  private set filter(filter: any) {
    ManagerDiscountModule.setDiscountsFilter(filter)
    this.fetchDiscounts()
  }

  private get discountItems() {
    return this.discounts.data.map(item => {
      return {
        ...item,
        sources: item.sources.length > 3 ? [...item.sources.map(source => item.sourceType.value === SaleUnitType.PACKAGES ? ({ ...source, title: `${(source as SalePackageUnitResource).course.name} ${(source as SalePackageUnitResource).course.type === CourseType.SPECIAL ? `${this.groupType[(source as SalePackageUnitResource).course.type]}` : `${this.groupType[(source as SalePackageUnitResource).groupType.value]}`} ${this.getMonthsRange((source as SalePackageUnitResource).months)}`.replace('&nbsp;', ' ') }) : source).slice(0, 3), { name: '...', value: '...' }] : item.sources.map(source => item.sourceType.value === SaleUnitType.PACKAGES ? ({ ...source, title: `${(source as SalePackageUnitResource).course.name} ${(source as SalePackageUnitResource).course.type === CourseType.SPECIAL ? `${this.groupType[(source as SalePackageUnitResource).course.type]}` : `${this.groupType[(source as SalePackageUnitResource).groupType.value]}`} ${this.getMonthsRange((source as SalePackageUnitResource).months)}`.replace('&nbsp;', ' ') }) : source),
        targets: item.targets.length > 3 ? [...item.targets.map(target => item.targetType.value === SaleUnitType.PACKAGES ? ({ ...target, title: `${(target as SalePackageUnitResource).course.name} ${(target as SalePackageUnitResource).course.type === CourseType.SPECIAL ? `${this.groupType[(target as SalePackageUnitResource).course.type]}` : `${this.groupType[(target as SalePackageUnitResource).groupType.value]}`} ${this.getMonthsRange((target as SalePackageUnitResource).months)}`.replace('&nbsp;', ' ') }) : target).slice(0, 3), { name: '...', value: '...' }] : item.targets.map(target => item.targetType.value === SaleUnitType.PACKAGES ? ({ ...target, title: `${(target as SalePackageUnitResource).course.name} ${(target as SalePackageUnitResource).course.type === CourseType.SPECIAL ? `${this.groupType[(target as SalePackageUnitResource).course.type]}` : `${this.groupType[(target as SalePackageUnitResource).groupType.value]}`} ${this.getMonthsRange((target as SalePackageUnitResource).months)}`.replace('&nbsp;', ' ') }) : target),
      }
    })
  }

  private get groupType() {
    const groupTypes: Record<string, string> = {}
    DictionaryModule.groupTypes.forEach(group => groupTypes[group.value] = group.name)
    groupTypes.special = 'Спецкурс'
    return groupTypes
  }

  private get footerOptions() {
    return tableFooterOptions
  }

  private get headers() {
    return [
      { cellClass: 'cell-width-15', text: 'Название', value: 'title' },
      { cellClass: 'cell-width-20', sortable: false, text: 'Источник скидки', value: 'sources' },
      { cellClass: 'cell-width-8', sortable: false, text: 'Скидка', value: 'salePrice' },
      { cellClass: 'cell-width-20', sortable: false, text: 'Цель', value: 'targets' },
      { cellClass: 'cell-width-20', sortable: false, text: 'Статус', value: 'dates' },
    ]
  }

  private get discountsStatus() {
    return DictionaryModule.discountsStatuses
  }

  private get discountsStatusValue() {
    const obj: Record<string | number, string> = {}
    this.discountsStatus.forEach(item => obj[item.value] = item.name.toLowerCase())
    return obj
  }

  private get discountsStatusColor() {
    return {
      [SaleStatus.DRAW]: 'grey',
      [SaleStatus.DELETED]: 'red',
      [SaleStatus.ACTIVE]: 'teal',
    }
  }

  private filterDate: string[] = this.filter.startDate && this.filter.endDate ? [this.filter.startDate, this.filter.endDate] : []

  private discounts: SaleResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Bind
  @Debounce(300)
  private fetchDiscounts() {
    ManagerDiscountModule.fetchDiscounts(tableOptionsToParams(this.filter))
      .then(response => this.discounts = response)
      .catch(this.notifyError)
  }

  private formatDateRange(educationPeriod: string[]) {
    return formatDateRange(educationPeriod[0], educationPeriod[1])
  }

  private handleFilter (field: any, value: string) {
    if (field === 'date') {
      if (value.length) {
        this.filter.endDate = value[1]
        this.filter.startDate = value[0]
      } else {
        this.filter.endDate = undefined
        this.filter.startDate = undefined
      }
    } else {
      this.filter[field] = value
    }

    this.filter = {
      ...this.filter,
      page: 1,
    }
  }

  private getMonthsRange(months: NameValueResource[]) {
    return getMonthsRange(months)
  }

  private handleRowClick(discount: SaleResource) {
    this.$router.push({
      name: 'manager.commerce.discount.item',
      params: {
        saleID: discount.id.toString(),
      },
    })
  }
}
