




































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import { formatDate } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    TextInput,
  },
  inheritAttrs: false,
})
export default class DateInput extends Vue {
  @Ref() menu!: any

  @Prop({ default: '' })
  private value!: string | string[]

  @Prop({ default: null })
  private label!: string

  @Prop({ default: null })
  private max!: string

  @Prop({ default: '1950-01-01' })
  private min!: string

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private selectYearFirst!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private range!: boolean

  private innerValue: string | string[] = this.range ? [] : ''
  private activePicker: 'DATE' | 'MONTH' | 'YEAR' = 'DATE'
  private showPicker = false

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get formattedDate () {
    if (Array.isArray(this.innerValue)) {
      return this.innerValue.length ? this.innerValue.map(date => formatDate(date, 'dd.MM.yyyy')).join(' - ') : ['', ''].join('')
    }
    return this.innerValue ? formatDate(this.innerValue, 'dd MMM yyyy', this.isLocalTimezone) : ''
  }

  private set formattedDate(value: any) {
    if (!value)
      this.innerValue = this.range ? [] : ''
  }

  private mounted () {
    this.innerValue = this.value
  }

  private handleInput (value: string | string[]) {
    this.menu.save(value)
    this.innerValue = value
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  private handleClear () {
    this.menu.save(this.range ? [] : '')
    this.innerValue = this.range ? [] : ''
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  @Watch('showPicker')
  private watchShowPicker (value: boolean) {
    if (value && this.selectYearFirst) {
      setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }

  @Watch('value')
  private watchValue (value: string | string[]) {
    this.innerValue = value
  }
}
