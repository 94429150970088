



import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import NotifyMixin from '@/mixins/NotifyMixin'
import DiscountsTable from '@/components/tables/DiscountsTable.vue'

@Component({
  components: {
    DiscountsTable,
  },
})
export default class SubjectDiscountList extends NotifyMixin {
  private metaInfo (): MetaInfo {
    return {
      title: 'Скидки',
    }
  }
}
