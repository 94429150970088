var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discount-table"},[_c('div',{staticClass:"filter filter_inner"},[_c('Select',{staticClass:"filter__col-3",attrs:{"options":_vm.discountsStatus,"placeholder":"Статус","label":"Статус","outlined":"","rounded":"","dense":"","clearable":true},on:{"input":function (value) { return _vm.handleFilter('status', value); }},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('TextInput',{staticClass:"filter__col-4",attrs:{"placeholder":"Название/позиция","label":"Название/позиция","append-icon":"$search","dense":"","outlined":"","rounded":"","clearable":true},on:{"input":function (value) { return _vm.handleFilter('query', value); }},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}}),_c('div',{staticClass:"filter__col-3"},[_c('DateInput',{attrs:{"label":"Даты проведения","placeholder":"Даты проведения","range":true,"dense":"","outlined":"","rounded":"","clearable":""},on:{"input":function (value) { return _vm.handleFilter('date', value); }},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1)],1),_c('VDataTable',{staticClass:"elevation-16 data-table_clickable rounded-top-off",attrs:{"headers":_vm.headers,"items":_vm.discountItems,"options":_vm.filter,"server-items-length":_vm.discounts.meta.total,"footer-props":_vm.footerOptions,"hide-default-footer":""},on:{"update:options":function($event){_vm.filter=$event},"click:row":_vm.handleRowClick,"update:items-per-page":function($event){_vm.filter.page = 1}},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))]),_c('VIcon',{staticClass:"v-data-table-header__icon",attrs:{"color":"teal darken-4"}},[_vm._v("$arrowUp")])]}},{key:"item.sources",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.sources.map(function (source) { return source.title || source.name; }).join(', ')))])]}},{key:"item.salePrice",fn:function(ref){
var item = ref.item;
return [(item.priceType === 'sum')?_c('div',{directives:[{name:"price",rawName:"v-price",value:(item.salePrice),expression:"item.salePrice"}],staticClass:"teal--text text--darken-4 text-body-1"}):_c('div',{staticClass:"teal--text text--darken-4 text-body-1"},[_vm._v(_vm._s(item.salePrice)+" %")])]}},{key:"item.targets",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.targets.map(function (target) { return target.title || target.name; }).join(', ')))])]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status"},[_c('Tag',{attrs:{"type":"bright","color":_vm.discountsStatusColor[item.status]}},[_vm._v(_vm._s(_vm.discountsStatusValue[item.status]))]),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDateRange([item.salesStartAt, item.salesEndAt])))])],1)]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }